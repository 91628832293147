<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-postulacion-export"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar Listado Postulaciones</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label for="empresa_id" class="col-md-5">Empresa</label>
              <v-select
                v-model="filtros.empresas"
                placeholder="Empresas"
                label="razon_social"
                class="form-control form-control-sm p-0 col-md-7"
                :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                :options="listasForms.empresas"
                :filterable="true"
              ></v-select>
            </div>
            <div class="form-group row">
              <label for="empresa_id" class="col-md-5">Tipo de Servicio</label>
              <v-select
                v-model="filtros.tipos_servicios"
                label="descripcion"
                class="form-control form-control-sm p-0 col-md-7"
                :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                :options="$parent.listasForms.tipoServicios"
                :filterable="false"
              ></v-select>
            </div>
            <div class="form-group row">
              <label for="empresa_id" class="col-md-5">Estado</label>
              <v-select
                v-model="filtros.estados"
                class="form-control form-control-sm p-0 col-md-7"
                :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                label="descripcion"
                :options="$parent.listasForms.estados"
                :filterable="false"
              ></v-select>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExce()"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "PostulacionExport", //llegada tambien
  components: { 
    Loading,
    vSelect },
  data() {
    return {
      cargando: false,
      filtros: {},
      //OBTIENE LAS LISTAS DE LOS CORRESPONDIENTES A LAS EMPRESAS
      listasForms: {
        empresas: [],
        sitios: [],
        tipo_ruta: [],
      },
    };
  },
  methods: {
    resetFilter(){
      this.filtros = {
        empresas: null,
        tipos_servicios: null,
        estado: null,
      };
    },

    async getEmpresas() {
      await axios.get("/api/admin/empresas/lista", {
        params: {
          linea_negocio_id: [4],
        },
      }).then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    //MANDA A LLAMAR LA FUNCION EXPORT EN EL BACK
    generarListadoExce() {
      this.cargando = true;

      // Se ajustan los filtros
      this.filtros.tipo_servicio = this.filtros.tipos_servicios ? this.filtros.tipos_servicios.numeracion : null;
      this.filtros.empresa_id = this.filtros.empresas ? this.filtros.empresas.id : null;
      this.filtros.estado = this.filtros.estados ? this.filtros.estados.numeracion : null;

      axios({
        method: "POST",
        url: "/api/cs/postulacionesInternas/export",
        data: this.filtros
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          if (!data.error) {
            location.href = data.url;
          } else {
            this.$swal({
              icon: "error",
              title: data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getEmpresas();
    this.cargando = false;
  },
};
</script>
